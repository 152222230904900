export default {
    'lbl_0000001': 'Forgot Password',
    'lbl_0000002': 'Verify your account',
    'lbl_0000003': 'It seems that you have lost your password. Help us to verify your identity by entering the One-Time Password (OTP) we will be sending to your email address or mobile number.',
    'lbl_0000004': 'Continue',
    'lbl_0000005': 'Email',
    'lbl_0000006': 'Not Me',
    'lbl_0000007': 'Mobile No.',
    'lbl_0000008': 'Untitled Page',
    'lbl_0000009': 'Send OTP',
    'lbl_0000010': 'Are you sure you want to proceed?',
    'lbl_0000011': 'Find your account',	
    'lbl_0000012': 'Please enter either your account number, serial number, username, email address or mobile number to find your account.',
    'lbl_0000013': 'Verification',
    'lbl_0000014': 'Create an account',
    'lbl_0000015': 'Proceed',
    'lbl_0000016': 'Verify',
    'lbl_0000017': 'Clear',
    'lbl_0000018': 'Resend Code',
    'lbl_0000019': 'Resend code in',
    'lbl_0000020': 'To ensure the security of your account and provide the best experience, we need to verify your mobile number and email address. Click on \'Mobile No.\' and \'Email\' to begin the verification process.',
    'lbl_0000021': 'Mobile No.',
    'lbl_0000022': 'Email',
    'lbl_0000023': 'Verify Mobile No.',
    'lbl_0000024': 'We have sent a One-Time Password (OTP) to your mobile number. Please check your messages and enter the OTP to complete the verification process.',
    'lbl_0000025': 'Error',
    'lbl_0000026': 'Mobile number has been successfully verified.',
    'lbl_0000027': 'Verified',
    'lbl_0000028': 'Verify Email',
    'lbl_0000029': 'We have sent a One-Time Password (OTP) to your email address. Please check your messages and enter the OTP to complete the verification process.',
    'lbl_0000030': 'Enroll PIN',
    'lbl_0000031': 'To keep your account secure, please enroll a 6-digit personal identification number (PIN).',
    'lbl_0000032': 'PIN',
    'lbl_0000033': 'Next',
    'lbl_0000034': 'Oops!',
    'lbl_0000035': 'Confirm PIN',
    'lbl_0000036': 'The PIN you entered do not match.',
    'lbl_0000037': 'Welcome Aboard!',
    'lbl_0000038': 'Your account has been successfully created.',
    'lbl_0000039': 'Getting Started',
    'lbl_0000040': 'Hello',
    'lbl_0000041': 'Username',
    'lbl_0000042': 'Password',
    'lbl_0000043': 'Log In',
    'lbl_0000044': 'version',
    'lbl_0000045': 'Something went wrong.',
    'lbl_0000046': 'Please enter the OTP (One-Time Password) sent to your registered email or mobile number to proceed with updating your password.',
    'lbl_0000047': 'New Password',
    'lbl_0000048': 'Confirm Password',
    'lbl_0000049': 'Update Password',
    'lbl_0000050': 'Update',
    'lbl_0000051': 'Your password has been updated successfully.',
    'lbl_0000052': 'Updated Successfully',
    'lbl_0000053': 'Username must be at least six characters and must not contain spaces and special characters.',
    'lbl_0000054': 'Edit Username',
    'lbl_0000055': 'Session has expired.',
    'lbl_0000056': 'Discard Changes',
    'lbl_0000057': 'Do you want to discard all changes?',
    'lbl_0000058': 'Success',
    'lbl_0000059': 'Username has been successfully saved.',
    'lbl_0000060': 'Your password must be at least eight characters long and include a combination of uppercase and lowercase letters, numbers, and special characters.',
    'lbl_0000061': 'Old Password',
    'lbl_0000062': 'New Password',
    'lbl_0000063': 'Confirm Password',
    'lbl_0000064': 'Edit Password',
    'lbl_0000065': 'Password has been successfully saved.',
    'lbl_0000066': 'Edit TIN',
    'lbl_0000067': 'Government ID',
    'lbl_0000068': 'TIN has been successfully saved.',
    'lbl_0000069': 'Deactivate Account',
    'lbl_0000070': 'We\'re sorry to see you go. Deactivating your account will temporarily disable your access to your profile, and you will no longer be able to use your account until you reactivate it.',
    'lbl_0000071': 'We have sent a One-Time Password (OTP) to your mobile number or email address to continue deactivating your account.',
    'lbl_0000072': 'Edit Mobile',
    'lbl_0000073': 'Please enter the One-Time Password (OTP) we have sent to your mobile number.',
    'lbl_0000074': 'Mobile number has been successfully updated.',
    'lbl_0000075': 'Edit Email',
    'lbl_0000076': 'Please enter the One-Time Password (OTP) we have sent to your email address.',
    'lbl_0000077': 'Email address has been successfully updated.',
    'lbl_0000078': 'Account',
    'lbl_0000079': 'Contacts',
    'lbl_0000080': 'Security',
    'lbl_0000081': 'Member Info',
    'lbl_0000082': 'Authentication',
    'lbl_0000083': 'Dashboard',
    'lbl_0000084': 'Profile',
    'lbl_0000085': 'Settings',
    'lbl_0000086': 'Help',
    'lbl_0000087': 'Edit PIN',
    'lbl_0000088': 'Old PIN',
    'lbl_0000089': 'New PIN',
    'lbl_0000090': 'Confirm PIN',
    'lbl_0000091': 'PIN has been successfully updated.',
    'lbl_0000092': 'The PINs don\'t match.',
    'lbl_0000093': 'Account No.',
    'lbl_0000094': 'Serial No.',
    'lbl_0000095': 'Regular',
    'lbl_0000096': 'Associate',
    'lbl_0000097': 'Basic Info',
    'lbl_0000098': 'TIN',
    'lbl_0000099': 'Registered',
    'lbl_0000100': 'Others',
    'lbl_0000101': 'Account',
    'lbl_0000102': 'Membership Type',
    'lbl_0000103': 'Age',
    'lbl_0000104': 'Gender',
    'lbl_0000105': 'Date of Birth',
    'lbl_0000106': 'QR Code',
    'lbl_0000107': 'Authentication Settings',
    'lbl_0000108': 'Login Automatically',
    'lbl_0000109': 'Keep current session in this device.',
    'lbl_0000110': 'Two-Factor Authentication (2FA)',
    'lbl_0000111': 'Require PIN to complete login process.',
    'lbl_0000112': 'Election has been officially closed.',
    'lbl_0000113': 'Register',
    'lbl_0000114': 'Day',
    'lbl_0000115': 'Hour',
    'lbl_0000116': 'Min',
    'lbl_0000117': 'Sec',
    'lbl_0000118': 'Registration starts in',
    'lbl_0000119': 'Voting starts in',
    'lbl_0000120': 'Voting ends in',
    'lbl_0000121': 'Vote Now',
    'lbl_0000122': 'Are you sure you want to register?',
    'lbl_0000123': 'Log Out',
    'lbl_0000124': 'Are you sure you want to leave?',
    'lbl_0000125': 'Our Company',
    'lbl_0000126': 'Satellite Offices',
    'lbl_0000127': 'Luzon',
    'lbl_0000128': 'Visayas',
    'lbl_0000129': 'Mindanao',
    'lbl_0000130': 'Help Center',
    'lbl_0000131': 'Help & Support',
    'lbl_0000132': 'Contact Us',
    'lbl_0000133': 'End User License Agreement',
    'lbl_0000134': 'Terms and Policies',
    'lbl_0000135': 'Privacy Policy',
    'lbl_0000136': 'Not Found',
    'lbl_0000137': 'By clicking "Leave," your account will be deactivated, and you will be logged out. You won’t be able to access your account until it is reactivated.',
    'lbl_0000138': 'See you soon!',
    'lbl_0000139': 'Leave',
    'lbl_0000140': 'Deactivate',
    'lbl_0000141': 'Welcome Ka-May-Ari',
    'lbl_0000142': 'Serving the AFP since 1981',
    'lbl_0000143': 'Sign Up',
    'lbl_0000144': 'Need Help?',
    'lbl_0000145': 'Last Name',
    'lbl_0000146': 'First Name',
    'lbl_0000147': 'I agree with the',
    'lbl_0000148': 'Join',
    'lbl_0000149': 'Welcome Back!',
    'lbl_0000150': 'We’re so glad to have you back after some time!',
    'lbl_0000151': 'Activate',
    'lbl_0000152': 'Please enter the OTP (One-Time Password) sent to your registered email or mobile number to proceed reactivating your account.',
    'lbl_0000153': 'Save',
    'lbl_0000154': 'You have successfully enrolled your PIN.',
    'lbl_0000155': 'Schedules',
    'lbl_0000156': 'Registration',
    'lbl_0000157': 'To',
    'lbl_0000158': 'Voting',
    'lbl_0000159': 'Please enter a new PIN.',
    'lbl_0000160': 'Remember to keep your password secure and avoid sharing it with others.',
    'lbl_0000161': 'Registration ends in',
    'lbl_0000162': 'You have already registered.',
    'lbl_0000163': 'Election',
    'lbl_0000164': 'You have already voted.',
    'lbl_0000165': 'Election OTP',
    'lbl_0000166': 'Enter the One-Time Password (OTP) sent to your mobile number or email address.',
    'lbl_0000167': 'Election Policy',
    'lbl_0000168': 'Scroll down at the bottom of the page to proceed in voting.',
    'lbl_0000169': 'Board of Directors',
    'lbl_0000170': 'Please select Seven (7) Candidates for the Board of Directors Position - At-Large Category.',
    'lbl_0000171': 'My Current Votes',
    'lbl_0000172': 'Board of Directors',
    'lbl_0000173': 'Election Committee',
    'lbl_0000174': 'Officer Category',
    'lbl_0000175': 'Enlisted Personnel Category',
    'lbl_0000176': 'Audit & Supervisory Committee',
    'lbl_0000177': 'Please finish voting first before leaving.',
    'lbl_0000178': 'You cannot go back until you have selected the required number of votes for this category.',
    'lbl_0000179': 'Are you sure you want to clear all selected candidates?',
    'lbl_0000180': 'You have already selected the maximum number of candidates. Please deselect a candidate to select again.',
    'lbl_0000181': 'Back',
    'lbl_0000182': 'Reminder',
    'lbl_0000183': 'At-Large Category',
    'lbl_0000184': 'No candidate selected.',
    'lbl_0000185': 'Please select One (1) Candidate for the Audit & Supervisory Committee Position.',
    'lbl_0000186': 'Please select One (1) Candidate for the Election Committee Position - Officer Category.',
    'lbl_0000187': 'Please select One (1) Candidate for the Election Committee Position - Enlisted Personnel Category.',
    'lbl_0000188': 'Please check the names below before submitting your votes.',
    'lbl_0000189': 'Vote Summary',
    'lbl_0000190': 'Submit',
    'lbl_0000191': 'No Category',
    'lbl_0000192': 'Vote',
    'lbl_0000193': 'Are you sure you want to submit your votes?',
    'lbl_0000194': 'Philippine Army Finance Center Producers Integrated Cooperative',
    'lbl_0000195': 'Summary of Votes',
    'lbl_0000196': 'Ballot No.',
    'lbl_0000197': 'Name',
    'lbl_0000198': 'Number of Votes',
    'lbl_0000199': 'Skip',
    'lbl_0000200': 'or',
    'lbl_0000201': 'Thank You!',
    'lbl_0000202': 'How do you feel about this election?',
    'lbl_0000203': 'Very Bad',
    'lbl_0000204': 'Bad',
    'lbl_0000205': 'Moderate',
    'lbl_0000206': 'Good',
    'lbl_0000207': 'Very Good',
    'lbl_0000208': 'Vote Limit Reached',
    'lbl_0000209': 'OTP',
    'lbl_0000210': 'Started:',
    'lbl_0000211': 'Submitted',
    'lbl_0000212': 'Thank you for voting!',
}