<template>
    <div>
        <div class="w-full h-screen flex flex-col">
            <div class="bg-primary">
                <div class="pt-3 px-3 flex">
                    <div class="h-menu w-menu"></div>
                    <div class="flex-grow text-center pt-6 pb-3">
                        <div v-text="lang('lbl_0000195')" class="text-xl text-white font-bold uppercase pt-6"></div>
                    </div>
                    <div class="h-menu w-menu"></div>
                </div>
                <div class="max-w-limit w-full mx-auto">
                    <div class="px-4">
                        <div class="bg-white py-2 w-full rounded-t"></div>
                    </div>
                </div>
            </div>
            <div class="flex-grow w-full overflow-auto pb-4">
                <ContentWrapper>
                    <div class="bg-white shadow border-b p-6 text-sm leading-normal rounded-b" style="font-family: Courier New, Courier, monospace">
                        <div class="w-full flex justify-center items-center mb-6">
                            <img v-bind:src="getLogo()" style="width: 60px" />
                        </div>
                        <div v-text="lang('lbl_0000194')" class="text-center text-lg font-bold uppercase"></div>
                        <div class="uppercase border-t-2 border-black mt-6">
                            <div class="mt-6"><span v-text="lang('lbl_0000196')"></span>: <span v-text="ballot"></span></div>
                            <div><span v-text="lang('lbl_0000197')"></span>: <span v-text="name"></span></div>
                            <div><span v-text="lang('lbl_0000093')"></span>: <span v-text="account_no"></span></div>
                            <div><span v-text="lang('lbl_0000094')"></span>: <span v-text="serial_no"></span></div>
                            <div><span v-text="lang('lbl_0000198')"></span>: <span v-text="count"></span></div>
                        </div>
                        <div class="border-t-2 border-black mt-6"></div>
                        <div class="border-t border-black" style="margin-top: 2px;"></div>
                        <div class="py-6">
                            <div>
                                <div class="font-bold text-md uppercase mb-4">I. <span v-text="lang('lbl_0000172')"></span></div>
                                <div v-text="lang('lbl_0000183')" class="text-sm uppercase underline truncate"></div>
                                <div class="mt-3 px-2 leading-loose">
                                    <div v-for="(item, index) in bod" v-bind:key="index" class="truncate text-xs"><span>{{ index + 1 }}.</span> <span v-text="item"></span></div>
                                </div>
                            </div>
                            <div class="mt-6 border-t-2 border-black pt-4">
                                <div class="font-bold text-md uppercase truncate mb-4">II. <span v-text="lang('lbl_0000176')"></span></div>
                                <div class="px-2 text-sm">
                                    <div v-for="(item, index) in audit" v-bind:key="index" class="truncate text-xs"><span>{{ index + 1 }}.</span> <span v-text="item"></span></div>
                                </div>
                            </div>
                            <div class="mt-6 border-t-2 border-black pt-4">
                                <div class="font-bold text-md uppercase mb-4">III. <span v-text="lang('lbl_0000173')"></span></div>
                                <div v-text="lang('lbl_0000174')" class="text-sm uppercase underline truncate"></div>
                                <div class="mt-3 px-2">
                                    <div v-for="(item, index) in elecom_of" v-bind:key="index" class="truncate text-xs"><span>{{ index + 1 }}.</span> <span v-text="item"></span></div>
                                </div>
                            </div>
                            <div class="mt-3">
                                <div v-text="lang('lbl_0000175')" class="text-sm uppercase underline truncate"></div>
                                <div class="mt-3 px-2">
                                    <div v-for="(item, index) in elecom_ep" v-bind:key="index" class="truncate text-xs"><span>{{ index + 1 }}.</span> <span v-text="item"></span></div>
                                </div>
                            </div>
                        </div>
                        <div class="border-t-2 border-black"></div>
                        <div class="pt-6 text-xs uppercase">
                            <div class="flex">
                                <div v-text="lang('lbl_0000210')" class="flex-grow"></div><div v-text="started"></div>
                            </div>
                            <div class="flex pb-6">
                                <div v-text="lang('lbl_0000211')" class="flex-grow"></div><div v-text="voted"></div>
                            </div>
                        </div>
                        <div class="border-t-2 border-black"></div>
                        <div v-if="ballot.length != 0" class="pt-4 flex justify-center pb-6">
                            <VueBarcode v-bind:value="ballot" class="papsi-barcode-w" />
                        </div>
                        <div v-text="lang('lbl_0000212')" class="uppercase text-lg text-grey-darkest text-center mt-6"></div>
                        <div v-text="lang('lbl_0000142')" class="text-sm text-grey-darkest text-center"></div>
                    </div>
                    <div ref="footerspacer"></div>
                </ContentWrapper>
            </div>
        </div>
        <div ref="footerbar" class="p-2 bg-white border-t w-full fixed z-footer papsi-ballot-footer">
            <ButtonControl v-on:click="exit" theme="error">
                {{ lang('lbl_0000015') }}
            </ButtonControl>
        </div>
    </div>
</template>

<script>
import datetime from 'date-and-time';
import VueBarcode from '@chenfengyuan/vue-barcode';
import ButtonControl from './../../components/controls/ButtonControl.vue';
import ContentWrapper from './../../components/controls/ContentWrapperControl.vue';
import mixin from './../../mixin.js';

export default {
    mixins: [
        mixin,
    ],
    components: {
        ButtonControl,
        ContentWrapper,
        VueBarcode,
    },
    data() {
        return {
            ballot: '',
            count: 1,
            name: '',
            account_no: '',
            serial_no: '',
            started: null,
            voted: null,
            votes: [],
            bod: [],
            elecom_of: [],
            elecom_ep: [],
            audit: [],
        }
    },
    mounted() {
        this.$emit('checkauth');
        this.$emit('nofooter', true);
        this.setSpacer();

        let state = this.$store.state,
            that = this;

        if (!state.ga.voted) {
            this.$router.push('/dashboard');
        }

        this.ballot = state.vote.ballot;
        this.name = state.info.first_name + ' ' + state.info.last_name;
        this.account_no = state.info.account_no;
        this.serial_no = state.info.serial_no;
        this.count = state.ga.total_votes;
        this.started = this.formatDate(state.vote.start_date);
        this.voted = this.formatDate(state.vote.end_date);

        state.candidates.bod.forEach(function(item) {
            let code = item.code;

            if (state.selected.bod.indexOf(code) !== -1) {
                that.bod.push(item.name);
            }
        });

        state.candidates.audit.forEach(function(item) {
            let code = item.code;

            if (state.selected.audit.indexOf(code) !== -1) {
                that.audit.push(item.name);
            }
        });

        state.candidates.elecom_of.forEach(function(item) {
            let code = item.code;

            if (state.selected.elecom_of.indexOf(code) !== -1) {
                that.elecom_of.push(item.name);
            }
        });

        state.candidates.elecom_ep.forEach(function(item) {
            let code = item.code;

            if (state.selected.elecom_ep.indexOf(code) !== -1) {
                that.elecom_ep.push(item.name);
            }
        });
    },
    methods: {
        setSpacer() {
            this.$refs.footerspacer.style.height = (parseInt(this.$refs.footerbar.offsetHeight) + 40) + "px";
        },
        getLogo() {
            return this.getMediaPath('/logo/pafcpic.png');
        },
        exit() {
            this.$router.push('/ga/election/ratings');
            this.clear();
        },
        clear() {
            let state = this.$store.state;

            state.selected.bod.splice(0, state.selected.bod.length);
            state.selected.elecom_of.splice(0, state.selected.elecom_of.length);
            state.selected.elecom_ep.splice(0, state.selected.elecom_ep.length);
            state.selected.audit.splice(0, state.selected.audit.length);
            state.election.ballot = null;
        },
        formatDate(date) {
            return datetime.format(new Date(date), 'MM/DD/YYYY');
        },
    }
}
</script>

<style scoped>
.papsi-ballot-footer {
    bottom: 0;
    left: 0;
}
.papsi-barcode-w {
    max-width: 140px !important;
}
</style>